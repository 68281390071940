import React, { useState } from 'react';
import styles from '@buddieshr/styles/components/lovePanel.module.scss';
import Link from 'next/link';
import { Button } from 'antd';
import LoveCard from '../loveCard';
import { LOVE, TALKED_ABOUT_US } from '@buddieshr/utils/constants';
import { sortReviews, useWindowSize } from '@buddieshr/utils/utils';
import Image from 'next/image';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';

const LovePanel = ({
  style = {},
  hideTitle = false,
  hideCTA = false,
  hideAppNames = false,
  small = false,
  showStars = false,
  filterKeyword,
  onlyFavorites = false,
  showCompanyLogo = false,
  displayLinkedin = false,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const size = useWindowSize();
  const limit = size.width < 690 || small ? 5 : 7;
  const filterKeywordNormalized = filterKeyword
    ? filterKeyword.toLowerCase()
    : null;

  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.inner}>
        {!hideTitle && (
          <div className={styles.title}>
            <h2>
              {"You're sure to make the right choice"}
              <Image
                src="/img/animated-emojis/Smiling%20Face%20with%20Halo.png"
                alt="Smiling Face with Halo"
                width="40"
                height="40"
                style={{ marginLeft: 10 }}
              />
            </h2>
          </div>
        )}
        {!hideTitle && (
          <div className={styles.subtitle}>
            Check out what <b>People Leaders</b> say about BuddiesHR
          </div>
        )}
        <div style={{ position: 'relative' }}>
          <div
            className={`${styles.content}${
              hideContent ? ` ${styles.hideContent}` : ''
            }`}
            style={{
              maxHeight: hideContent ? '600px' : 'none',
              overflow: 'hidden',
            }}
          >
            {displayLinkedin &&
              TALKED_ABOUT_US.map((e) => (
                <div key={e.name} style={{ height: 'auto' }}>
                  <LoveCard
                    {...e}
                    // showStars={showStars}
                    key={e.name}
                    small={small}
                    hideAppNames={hideAppNames}
                    style={{ height: '100%' }}
                  />
                </div>
              ))}
            {sortReviews(LOVE)
              .filter((e) =>
                filterKeyword
                  ? e.apps.includes(filterKeywordNormalized)
                  : true
              )
              .filter((e) => (onlyFavorites ? e.favorite : true))
              .slice(0, limit)
              .map((l) => (
                <div key={l.name} style={{ height: 'auto' }}>
                  <LoveCard
                    style={{ height: '100%' }}
                    small={small}
                    key={l.name}
                    {...l}
                    showStars={showStars}
                    showCompanyLogo={showCompanyLogo}
                    hideAppNames={hideAppNames}
                  />
                </div>
              ))}
          </div>
          {hideContent && (
            <>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '260px',
                  background:
                    'linear-gradient(rgba(255, 255, 255, 0) 9%, rgb(255, 255, 255) 84%)',
                  pointerEvents: 'none',
                }}
              />
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => setHideContent(false)}
                  type="link"
                  icon={<DownOutlined />}
                >
                  Show more
                </Button>
              </div>
            </>
          )}
        </div>
        {!hideCTA && !hideContent && (
          <div className={styles.actions}>
            <Link href="/reviews">
              <Button type="primary" size="large" style={{ minWidth: 220 }}>
                Read more reviews
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LovePanel;
