import React from 'react';
const { Rate } = require('antd');
import Image from 'next/image';
import { G2_LINK } from '@buddieshr/utils/constants';
import { useTranslation } from 'react-i18next';
import styles from '@buddieshr/styles/components/g2Logos.module.scss';
import Link from 'next/link';
const G2Logos = ({
  fullWidth = false,
  withTitle = true,
  withBackground = true,
  withLoveLink = false,
  style = {},
  className,
}) => {
  const { t } = useTranslation();
  const imageSize = fullWidth
    ? { width: 110, height: 142 }
    : { width: 92 * 0.7, height: 120 * 0.7 };
  return (
    <div
      style={style}
      className={`${styles.Rated_wrapper} ${
        fullWidth ? styles.Rated_wrapper_fullwidth : ''
      }${className ? ` ${className}` : ''}`}
    >
      <div
        className={styles.Rated_inner}
        style={{
          background: withBackground
            ? 'linear-gradient(343deg, #284192 10%, #1f3a45 54%)'
            : 'none',
        }}
      >
        {withTitle && (
          <h2 className={styles.Rated_title}>{t('awardWinningApp')}</h2>
        )}
        <div className={styles.Rated_rate_container_wrapper}>
        <div className={styles.Rated_rate_container}>
          <Image
            className={styles.Rated_image}
            src="/img/g2.png"
            alt="g2 lmogo"
            width={38}
            height={38}
          />
          <div className={styles.Rated_rate}>
            <Rate value={5} disabled size="small" />
            <div className={styles.Rated_ratedText}>
              <a href={G2_LINK} target="_blank" rel="noreferrer">
                {t('rated', { rates: 100 })}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.Rated_badges}>
          <div className={styles.Rated_badge_element}>
            <Image
              src="/img/g2badges_2025/ProductivityBots_Leader_Leader.svg"
              {...imageSize}
              alt="best results slack birthday bot"
            />
          </div>
          <div className={styles.Rated_badge_element}>
            <Image
              src="/img/g2badges_2025/EmployeeEngagement_HighPerformer_HighPerformer.svg"
              {...imageSize}
              alt="best usability slack birthday bot"
            />
          </div>
          <div className={styles.Rated_badge_element}>
            <Image
              src="/img/g2badges_2025/ProductivityBots_BestResults_Total.svg"
              {...imageSize}
              alt="easiest to use slack birthday bot"
            />
          </div>
          {/* <div className={styles.Rated_badge_element}>
            <Image
              src="/img/g2badges_2025/ProductivityBots_BestUsability_Total.svg"
              {...imageSize}
              alt="slack bot Best usability"
            />
          </div>
          <div className={styles.Rated_badge_element}>
            <Image
              src="/img/g2badges_2025/ProductivityBots_BestRelationship_Total.svg"
              {...imageSize}
              alt="high performer, best slack birthday bot"
            />
          </div> */}
        </div>
        </div>
      
        {withLoveLink && (
          <div className={styles.wallOfLoveLinkWrapper}>
            <h3 className={styles.wallOfLoveLink}>
              Check out our{' '}
              <Link href="/reviews" className={styles.linkLove}>
                Wall of love ❤️
              </Link>
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default G2Logos;
