import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Rate, Tag, Tooltip } from 'antd';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import styles from '@buddieshr/styles/reviews.module.scss';
import { ORIGINS } from '@buddieshr/utils/constants';
import { getCountLabelInteger } from '@buddieshr/utils/utils';

const getOriginContent = (origin) => {
  switch (origin) {
    case ORIGINS.G2:
      return (
        <div>
          <Tooltip title="Written on G2">
            <Image src="/img/g2.png" alt="g2 logo" width={22} height={22} />
          </Tooltip>
        </div>
      );
    case ORIGINS.LINKEDIN:
      return (
        <div>
          <Tooltip title="Read full post on Linkedin">
            <Image
              src="/img/linkedin_80.png"
              alt="linkedin logo"
              width={22}
              height={22}
            />
          </Tooltip>
        </div>
      );
    case ORIGINS.YOUTUBE:
      return (
        <div>
          <Tooltip title="View Youtube video">
            <Image
              src="/img/youtube_80.png"
              alt="youtube logo"
              width={80 * 0.4}
              height={55 * 0.4}
            />
          </Tooltip>
        </div>
      );
    case ORIGINS.EMAIL:
    default:
      return (
        <div>
          <Tooltip title="Received by email">
            <MailOutlined />
          </Tooltip>
        </div>
      );
  }
};

const LoveCard = ({
  content,
  name,
  profilePicture,
  companyLogo,
  date,
  role,
  origin,
  link,
  showStars = false,
  showCompanyLogo = false,
  apps,
  nbFollowers,
  small = false,
  messageShort,
  isTopVoice = false,
  hideAppNames = false,
  style = {},
}) => (
  <div className={styles.loveElementWrapper}>
    <div className={styles.loveElementInner} style={style}>
      {/* {showCompanyLogo && companyLogo && (
        <div style={{ marginBottom: 12 }}>{companyLogo}</div>
      )} */}
      <div className={styles.loveElementNameWrapper}>
        <Avatar
          className={styles.loveElementNameAvatar}
          src={profilePicture}
          size={44}
          style={{ minWidth: 44 }}
          icon={<UserOutlined />}
          alt={`Profile of ${name}`}
        />
        <div className={styles.loveElementNameContent}>
          <span className={styles.loveElementName}>{name}</span>
          {role && role.length > 0 && (
            <div className={styles.loveElementRole}>{role}</div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
            {isTopVoice && (
              <span className={styles.topVoice}>
                <Image
                  src="/img/linkedin_posts/topvoice.png"
                  alt="top voice linkedin"
                  width={483 * 0.2}
                  height={169 * 0.2}
                />
              </span>
            )}
            {nbFollowers && (
              <span className={styles.followers}>{`${getCountLabelInteger(
                nbFollowers
              )} followers`}</span>
            )}
          </div>
        </div>
        {origin && (
          <div className={styles.loveElementOrigin}>
            {link && link.length > 0 ? (
              <Link target="_blank" href={link || ''}>
                {getOriginContent(origin)}
              </Link>
            ) : (
              getOriginContent(origin)
            )}
          </div>
        )}
      </div>

      {showStars && origin !== ORIGINS.LINKEDIN && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 18 }}>
          <Rate defaultValue={5} disabled />
          &nbsp;<span style={{ fontSize: '15px' }}>(5)</span>
        </div>
      )}

      <div className={styles.loveElementContent}>
        {small && messageShort ? messageShort : content}
      </div>
      {date && (
        <div className={styles.loveElementDate}>
          {moment(new Date(date)).format('MMM DD, YYYY')}
        </div>
      )}
      {apps && apps.length > 0 && !hideAppNames && (
        <div className={styles.appsTag}>
          {apps.map((a) => (
            <Tag color="blue" key={`tag_${name}_${a}`}>
              <span style={{ textTransform: 'capitalize' }}>{a}</span>
            </Tag>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default LoveCard;
